import React, {useState, useEffect} from 'react';
import Fade from 'react-reveal/Fade';
import {useSpring, animated, UseSpringProps} from 'react-spring';
import useHover from '@hzdg/use-hover';
import {
  ResponsiveContainer,
  TwoColumn,
  useContainerSize,
} from '@components/layout';
import {Headline, Paragraph} from '@components/typography';
import {Link} from '@components/Link';
import {styled, Colors, useTheme, Fonts, ThemeProvider} from '@styles';
import {AmbientVideo} from '@components/Video';
import {Team} from '@util/useTeams';
import {Section} from '@components/sectioning';
import CareersDataLoader, {CareersRenderProps} from '@util/CareersDataLoader';

interface VideoProps {
  id: string;
  videoSrc: string;
  imgSrc: string;
}

type ListItemProps = Team & {
  setHoveredTeam(object: object): object;
  setBubbleHoveredState(state: boolean): boolean;
};

/**
 * This maps the hovered link with the render position and
 * transform to be applied to the handwritten note image
 */
const CELL_MAP = [
  {
    teamId: '8',
    cellArea: 'center',
    transform: 'translate(-0.5em, 0) rotate(-5deg)',
  },
  {
    teamId: '12',
    cellArea: 'right',
    transform: 'translate(-1em, 3.5em) scale(2.3)',
  },
  {
    teamId: '104',
    cellArea: 'rightMiddle',
    transform: 'translate(0, 3.7em) scale(1.8)',
  },
  {
    teamId: '108',
    cellArea: 'center',
    transform: 'translate(-0.8em, 0) scale(1.5)',
  },
  {
    teamId: '110',
    cellArea: 'bottom',
    transform: 'translate(-1em, 0) scale(1.8)',
  },
  {
    teamId: '118',
    cellArea: 'center',
    transform: 'translate(-1.5em, 0) scale(1.3)',
  },
  {
    teamId: '120',
    cellArea: 'center',
    transform: 'translate(0, -2em) scale(1.5)',
  },
];

const Container = styled(Section).withConfig({
  componentId: 'ourTeamsContainer'
})`
  padding: 4em 2em;
  width: 100%;
  background: ${Colors.Midnight};
  box-sizing: border-box;

  &.wide {
    padding: 10em 2em;
  }
`;

const InnerContainer = styled(TwoColumn).withConfig({
  componentId: 'ourTeamsInnerContainer'
})`
  margin: 0 auto;
  width: 100%;
  grid-gap: 0;
  .wide & {
    max-width: 75%;
    grid-column-gap: 7em;
  }
`;

const Title = styled(animated.div).withConfig({
  componentId: 'ourTeamsTitle'
})`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${30 / 16}em;
  line-height: ${37 / 30};
  letter-spacing: ${-0.82 / 30}em;
  margin-bottom: 1em;
  text-align: left;

  .wide & {
    font-size: ${34 / 16}em;
    line-height: ${42 / 34};
    letter-spacing: ${-0.88 / 34}em;
  }
`;

const VideoContainer = styled.div.withConfig({
  componentId: 'ourTeamsVideoContainer'
})`
  display: inline-grid;
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(5, minmax(120px, 1fr));
  grid-template-rows: repeat(4, 92px);
  grid-template-areas:
    '. left leftMiddle rightMiddle right'
    '. top video video video'
    '. center video video video'
    '. bottom video video video';
`;

const Content = styled.div.withConfig({
  componentId: 'ourTeamsContent'
})`
  margin-bottom: 1em;
`;

const VideoCell = styled.div.withConfig({
  componentId: 'ourTeamsVideoCell'
})`
  grid-area: video;
  width: 100%;
  height: 100%;
`;

const Cell = styled.div.withConfig({
  componentId: 'ourTeamsCell'
})`
  grid-area: ${({area}: {area: string}) => area};
`;

const Image = styled.img.withConfig({
  componentId: 'ourTeamsImage'
})`
  width: 100%;
  transform: ${({transform}: {transform: string}) => transform};
`;

const ListContainer = styled.ul.withConfig({
  componentId: 'ourTeamsListContainer'
})`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = ({
  id,
  title,
  slug,
  setBubbleHoveredState,
  setHoveredTeam,
  handwrittenSvg,
  handwrittenVideo,
}: ListItemProps): JSX.Element => {
  const theme = useTheme();
  const [isHovering, hoverProps] = useHover();
  const {color} = useSpring<UseSpringProps>({
    to: {
      color: isHovering ? theme.ctaHoverFg : theme.fg,
    },
  });

  useEffect(() => {
    isHovering
      ? setHoveredTeam({
          id,
          videoSrc: handwrittenVideo ? handwrittenVideo.url : '',
          imgSrc: handwrittenSvg ? handwrittenSvg.url : '',
        })
      : null;
    setBubbleHoveredState(isHovering);
  }, [isHovering]);

  return (
    <li>
      <Link
        href={`/careers/${slug}/`}
        onFocus={hoverProps.onMouseEnter ? hoverProps.onMouseEnter : null}
        onBlur={hoverProps.onMouseLeave ? hoverProps.onMouseLeave : null}
        {...hoverProps}
      >
        <Title style={{color}}>{title}</Title>
      </Link>
    </li>
  );
};

const Video = ({id, videoSrc, imgSrc}: VideoProps): JSX.Element | false => {
  const cellPosition = CELL_MAP.find(item => item.teamId === id);
  const {wide} = useContainerSize();
  return (
    wide && (
      <div>
        <VideoContainer>
          <Cell area={cellPosition ? cellPosition.cellArea : ''}>
            <Image
              src={imgSrc}
              alt="handwritten note"
              transform={cellPosition ? cellPosition.transform : 'unset'}
            />
          </Cell>
          <VideoCell>
            <AmbientVideo videoSrc={videoSrc} />
          </VideoCell>
        </VideoContainer>
      </div>
    )
  );
};

const OurTeamsRenderer = ({teams}: CareersRenderProps): JSX.Element => {
  const [hoveredTeam, setHoveredTeam] = useState();
  const [bubbleHoveredState, setBubbleHoveredState] = useState(false);
  const maxTenTeams = teams ? teams.slice(0, 10) : [];
  return (
    <ThemeProvider
      theme={{
        fg: Colors.White,
        ctaHoverFg: Colors.Robin,
      }}
    >
      <ResponsiveContainer as={Container}>
        <InnerContainer>
          <Content>
            <Headline>Our teams.</Headline>
            <Paragraph>
              Engineers. Marketers. Curriculum Designers. Academic counselors.
              And more. At 2U, our teams are comprised of the best in their
              fields and are united by our commitment to delivering world-class
              education, supporting partners, and empowering students.
            </Paragraph>
            <Fade bottom when={bubbleHoveredState} distance="0.5em">
              <Video {...hoveredTeam} />
            </Fade>
          </Content>
          <ListContainer>
            {maxTenTeams.map(team => (
              <ListItem
                key={team.id}
                {...team}
                setHoveredTeam={setHoveredTeam}
                setBubbleHoveredState={setBubbleHoveredState}
              />
            ))}
          </ListContainer>
        </InnerContainer>
      </ResponsiveContainer>
    </ThemeProvider>
  );
};

export default function OurTeams(): JSX.Element {
  return <CareersDataLoader>{OurTeamsRenderer}</CareersDataLoader>;
}
