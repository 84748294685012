import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import Hero from '@components/Hero';
import {ResponsiveContainer, FontScale} from '@components/layout';
import JobSearchBar from '@components/JobSearchBar';
import TeamProvider from '@components/JobSearchBar/TeamProvider';
import {HeroSectionCallout} from '@components/SectionCallout';
import {
  IconListSection,
  CrosslinkSection,
  OneImageWithContentSection,
  TwoImagesWithContentSection,
} from '@components/sections/';
import {ThemeProvider, Colors} from '@styles';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';
import {QuoteWithVideo} from '@components/Quote';
import GuidingPrinciples from '@components/GuidingPrinciples';
import OurTeams from '@components/OurTeams';
import {getSrc} from 'gatsby-plugin-image'

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}

interface Page {
  searchDescription: string;
  seoTitle: string;
  title: string;
  id: string;
  body: Block[];
  hero: Block[];
  icon: object;
}

export default function Careers({data}: {data: Data}): JSX.Element {
  const {page} = data.cms;
  const {hero, body, seoTitle, searchDescription, canonicalUrl} = page;
  const {
    intro,
    testimonial,
    video,
    principles,
    diversity,
    internships,
    crossLink,
    iconList,
  } = getDataFromBlockTypes(body);
  const {header, image} = getDataFromBlockTypes(hero);
  const imageUrl = getSrc(image.image);
  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} image={imageUrl} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider
        theme={{
          minPadding: '2em',
          maxPadding: '4em 0',
          maxWidth: '100%',
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider
            theme={{
              fg: Colors.White,
              bg: Colors.Transparent,
              maxWidth: '600px',
            }}
          >
            <Hero
              image={image.image}
              heroSectionType={'tall'}
            >
              <HeroSectionCallout heroProps={[header]} />
            </Hero>
          </ThemeProvider>
          <TeamProvider>
            <JobSearchBar />
          </TeamProvider>
          <ThemeProvider theme={{maxPadding: 'unset'}}>
            <TwoImagesWithContentSection
              {...intro}
              noPadding
              offset={false}
              margin="0 0 6em"
            />
          </ThemeProvider>
          <QuoteWithVideo copy={testimonial.testimonialBlock.body} {...video} />
          {/* <GuidingPrinciples {...principles} /> */}
          {diversity && (
            <ThemeProvider
              theme={{
                ctaFg: Colors.Blue,
                ctaBg: Colors.Transparent,
                ctaHoverFg: Colors.White,
                ctaHoverBg: Colors.Blue,
              }}
            >
              <OneImageWithContentSection {...diversity} />
            </ThemeProvider>
          )}
          <OurTeams />
          <IconListSection
            margin="80px auto 0"
            listType="location"
            header={iconList && iconList.header ? iconList.header : ''}
            copy={iconList && iconList.subheader ? iconList.subheader : ''}
          />
          <ThemeProvider
            theme={{
              maxPadding: 0,
            }}
          >
            <TwoImagesWithContentSection {...internships} />
          </ThemeProvider>
          <ThemeProvider theme={{bg: Colors.FogLight}}>
            <CrosslinkSection {...crossLink} />
          </ThemeProvider>
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  fragment CareersFragment on CMS_CareerPage {
    seoTitle
    searchDescription
    canonicalUrl
    hero {
      ...heroCmsImageFragment
      ...headerBlockFragment
    }
    body {
      ...introBlockFragment
      ...videoCmsFragment
      ...testimonialSimpleBlockFragment
      ...iconImageGridFragment
      ...headerBlockFragment
      ...sectionBodyImageFragment
      ...sectionBodyMediaGrid2Block
      ...crosslinkBlockFragment
    }
  }
  query Careers {
    cms {
      page(url: "/home/careers/") {
        ...CareersFragment
      }
    }
  }
`;
