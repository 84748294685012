import React from 'react';
import {styled, ThemeProvider, Colors} from '@styles';
import {ResponsiveContainer} from '@components/layout';
import Quote from './';
import {InlineYoutubeVideo} from '@components/Video';
import {FluidObject} from 'gatsby-plugin-image';
import {Section} from '@components/sectioning';

interface Props {
  copy: string;
  video: {
    url: string;
  };
  image?: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  youtube: string;
}

const Container = styled(Section).withConfig({
  componentId: 'quoteWithVideoContainer'
})`
  display: flex;
  flex-direction: column-reverse;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  .wide & {
    max-width: 75%;
    flex-direction: row;
  }
`;

const VideoContainer = styled.div.withConfig({
  componentId: 'quoteWithVideoVideoContainer'
})`
  flex: 1;
`;

const QuoteContainer = styled.div.withConfig({
  componentId: 'quoteWithVideoQuoteContainer'
})`
  display: flex;
  align-items: center;
  position: relative;

  .wide & {
    max-width: 380px;
    right: 72px;
  }
`;

export const QuoteWithVideo = ({
  copy,
  video,
  youtube,
  image,
}: Props): JSX.Element => (
  <ThemeProvider
    theme={{
      fg: Colors.Charcoal,
      quoteFg: Colors.Blue,
      minPadding: '2em',
    }}
  >
    <ResponsiveContainer as={Container}>
      <VideoContainer>
        <InlineYoutubeVideo
          videoId={youtube}
          placeholderType={image ? `image` : video ? 'video' : null}
          placeholderImage={image ? image.childImageSharp : null}
          placeholderVideo={video ? video.url : null}
        />
      </VideoContainer>
      <QuoteContainer>
        <Quote text={copy} />
      </QuoteContainer>
    </ResponsiveContainer>
  </ThemeProvider>
);
